import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div className="footerApp">
        <p>Castañeda Hnos. Distribuidora  || Mar del Plata || 2020</p>
      </div>
    )
  }
}
