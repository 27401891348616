import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default class ArticuloDetalle extends Component {

  state = {
    qarticulo: [],
    id: '',
    _id: ''
  }

  async componentDidMount() {
    // const res = await axios.get('http://localhost:4000/api/articulos/' + this.props.match.params.id);
    const res = await axios.get('https://castaneda.herokuapp.com/api/articulos/' + this.props.match.params.id);
    this.setState({ qarticulo: res.data });
  }

  render() {
    return (
      <div className="container">
        <div className="card border-secondary mb-3">
          <div className="card-header d-flex justify-content-between">
            <h5> {this.state.qarticulo.codigo + " - " + this.state.qarticulo.detalle} </h5>
          </div>
          <div className="card-body text-secondary p-4">
            {/* <img src="./../.jpg" class="rounded float-right" alt="..." /> */}
            <img className="img-thumbnail float-right"
              height="100%"
              width="200"
              alt="Sin imagen disponible"
              // {this.state.qarticulo.codigo}
              src={'./../images/' + this.state.qarticulo.codigo + '.jpg'}
                    onError={
                    (e) => {e.target.onerror = null;
                    e.target.src = "./../images/Logo Negro.png"}} >
            </img>
            <p className="p-2">Descripción: <b><br />
              {this.state.qarticulo.descripcion}
            </b></p>
            <p className="p-2">Precio de venta: <b>
              {new Intl.NumberFormat('es-AR', {
                useGrouping: true,
                style: 'currency',
                currency: 'ARS'
              }).format(" " + this.state.qarticulo.preciodeventa)}
            </b></p>
            <p className="p-2">Clasificadores: <b>
              {(this.state.qarticulo.nivel1 !== "SIN CLASIFICAR" ? this.state.qarticulo.nivel1 + " | " : "") +
               (this.state.qarticulo.nivel2 !== "SIN CLASIFICAR" ? this.state.qarticulo.nivel2 + " | " : "") +
               (this.state.qarticulo.nivel3 !== "SIN CLASIFICAR" ? this.state.qarticulo.nivel3 + " | " : "") + 
               (this.state.qarticulo.nivel4 !== "SIN CLASIFICAR" ? this.state.qarticulo.nivel4 : "") }
            </b></p>
            <Link className="btn btn-primary" to={"/"}>
              Volver
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
