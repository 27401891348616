import React, { Component } from 'react'

export default class IniciarSesion extends Component {
  render() {
    return (
      <div className="container">
        Login de clientes
      </div>
    )
  }
}
