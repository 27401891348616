import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navegacion from './components/Navegacion';
import Grilla from './components/Grilla';
import ArticuloDetalle from './components/ArticuloDetalle';
import Registrarse from './components/Registrarse';
import IniciarSesion from './components/IniciarSesion';
import PieDePagina from './components/PieDePagina'

class App extends Component {

  render () {
    return (
      <Router >
        <Navegacion />
        <div className="container p-4">
          <Route path="/" exact component={Grilla} />
          <Route path="/articulos/:id" component={ArticuloDetalle} />
          <Route path="/Registro" component={Registrarse} />
          <Route path="/Usuario" component={IniciarSesion} />      
        </div>
        <PieDePagina />
      </Router>
    );
  }
}

export default App;
