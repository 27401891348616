import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import PieDePagina from './PieDePagina';

let ultimaBusqueda = "";

export default class Grilla extends Component {

  state = {
    qarticulos: [],
    buscardatos: ''
  }

  async componentDidMount() {

    ultimaBusqueda = localStorage.getItem('ultimabusqueda');
    console.log("buscar: ", ultimaBusqueda);

    if (ultimaBusqueda !== '') {
      this.setState({ buscardatos: ultimaBusqueda });
      const res = await axios.post('https://castaneda.herokuapp.com/api/busquedas', {
        buscardatos: ultimaBusqueda
      });
      this.setState({ qarticulos: res.data });
    } else {
      const res = await axios.get('https://castaneda.herokuapp.com/api/articulos');
      this.setState({ qarticulos: res.data });
    };
  }

  onChangebuscardatos = (e) => {
    this.setState({
      buscardatos: e.target.value
    });
  }

  onSubmit = async e => {
    e.preventDefault();
    const res = await axios.post('http://castaneda.herokuapp.com/api/busquedas', {
      buscardatos: this.state.buscardatos
    });
    localStorage.setItem('ultimabusqueda', this.state.buscardatos);
    this.setState({ qarticulos: res.data });
  }

  render() {
    return (
      <div className="container">

        <div className="card card-body">
          <div>
            <h5 className="p-1 text-center">Buscar en Castañeda Hnos</h5>
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="input-group">
              <input type="text" className="form-control"
                placeholder="Ej: termostato ford" onChange={this.onChangebuscardatos} />
              <div className="input-group-append">
                <button type="buttom" className="btn btn-primary">
                  <img className="float-left"
                    width="21"
                    alt="Buscar"
                    src={'./../Logo Blanco.png'}>
                  </img>
                  {/* <i className="material-icons center">Buscar</i> */}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="alert alert-primary p-2" role="alert">
          <b> {this.state.qarticulos.length + " "} </b>
            artículos encontrados con el texto:
          <b> {" " + this.state.buscardatos} </b>
        </div>

        <div className="row">
          {
            this.state.qarticulos.map(articulo => (
              <div className="col-md-3 p-2" key={articulo._id}>

                <div className="card">
                  <img className="card-img-top"
                    src={'./../images/' + articulo.codigo + '.jpg'}
                    onError={
                      (e) => {
                        e.target.onerror = null;
                        e.target.src = "./../images/Logo Negro.png"
                      }}
                    alt="Sin imagen disponible" />
                  <div className="card-body">
                    <h6 className="card-title">
                      <Link to={"/articulos/" + articulo._id}>
                        {articulo.codigo + " - " + articulo.detalle}
                        {/* <a href= {/articulos/ + articulo._id}>  */}
                        {/* </a> */}
                      </Link>
                    </h6>
                    <p className="card-text">
                      { (articulo.nivel1 !== "SIN CLASIFICAR" ? articulo.nivel1 + " | " : "") +
                        (articulo.nivel2 !== "SIN CLASIFICAR" ? articulo.nivel2 + " | " : "") +
                        (articulo.nivel3 !== "SIN CLASIFICAR" ? articulo.nivel3 + " | " : "") +
                        (articulo.nivel4 !== "SIN CLASIFICAR" ? articulo.nivel4 : "") }
                      {/* {articulo.nivel1 + " | " + articulo.nivel2 + " | " +
                        articulo.nivel3 + " | " + articulo.nivel4} */}
                    </p>
                  </div>

                </div>

              </div>
            ))
          }
        </div>
        {/* <PieDePagina /> */}

      </div>
    )
  }

}
